<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-form label-width="80px">
                <el-row>
                    <el-col :lg="24">
                        <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                            <el-row>
                                <el-col :lg="24" style="line-height: 27px;">
                                    <strong style=" font-size: 16px; ">当前：所有科目账-查账</strong>
                                </el-col>
                            </el-row>
                        </div>
                    </el-col>
                    <el-col :lg="24">
                        <el-row>
                            <el-col :lg="7" :sm="12" :md="8">
                                <el-date-picker
                                        v-model="pickerMonthRange"
                                        style=" margin-top: 8px;padding-top: 2px; width: 200px"
                                        type="monthrange"
                                        align="right"
                                        size="mini"
                                        @change="$_pickerMonthRangeEvent()"
                                        unlink-panels
                                        range-separator="至"
                                        start-placeholder="开始月份"
                                        end-placeholder="结束月份"
                                        :picker-options="pickerOptions">
                                </el-date-picker>
                                <el-date-picker
                                        style=" margin-top: 8px;padding-top: 2px; width: 220px"
                                        v-model="pickerDate"
                                        type="daterange"
                                        size="mini"
                                        :disabled="false"
                                        :clearable="false"
                                        :picker-options="pickerOptions"
                                        range-separator="至"
                                        start-placeholder="开始日期"
                                        end-placeholder="结束日期"
                                        align="right">
                                </el-date-picker>
                            </el-col>
                            <el-col :sm="12" :md="5">
                                <el-checkbox style="margin-top: 8px;" v-model="formData.noHappendMoney" @change="search()">无发生额不显示</el-checkbox>
                                <el-checkbox style="margin-top: 8px;" v-model="formData.noMoney" @change="search()">无余额不显示</el-checkbox>
                            </el-col>
                            <el-col :sm="12" :md="5">
                                <el-form-item label="科目:">
                                    <el-select
                                            ref="roleSubject" v-model="formData.roleSubject"
                                            style="width: 100%"
                                            size="mini"
                                            clearable
                                            multiple
                                            collapse-tags
                                            filterable
                                            default-first-option
                                            placeholder="可选多选">
                                        <el-option
                                                v-for="item in roleSubjectList"
                                                :key="item.id_subject"
                                                :label="item.id_subject +'-'+item.subject_name"
                                                :value="item.id_subject">
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :sm="12" :md="4">
                                <el-button style=" margin-top: 8px; margin-left: 8px; " type="primary" round @click="search()"
                                           size="mini">搜索
                                </el-button>
                                <el-button type="primary" size="mini" @click="$_openType({path: '/cashBanksDetail', query: {'id_subject': $refs.xGrid.getCurrentRecord().id_subject,
                                subject_name : $refs.xGrid.getCurrentRecord().subject_name,startDate: formatDate(pickerDate[0],'yyyy-MM-dd'),endDate: formatDate(pickerDate[1],'yyyy-MM-dd')}})" :disabled="$refs.xGrid == null || $refs.xGrid.getCurrentRecord() == null"
                                           style="margin-bottom: 2px;margin-top: 2px">明细
                                </el-button>
                                <el-button type="primary" size="mini" @click="$router.back()"
                                           style="margin-bottom: 2px;margin-top: 2px">返回
                                </el-button>
                            </el-col>

                            <el-col :sm="12" :md="3">
                                <el-row>
                                    <el-col class="pull-left" :lg="4">
                                        <vxe-toolbar class="pull-left" style=" height: 42px; " custom export
                                                     ref="xToolbar2">
                                        </vxe-toolbar>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :lg="24">
                        <vxe-grid
                                border
                                resizable
                                show-overflow
                                keep-source
                                ref="xGrid"
                                align="right"
                                size="mini"
                                :height="(this.getViewHeight() - 130)+''"
                                :checkbox-config="{labelField:'id'}"
                                highlight-current-row
                                show-footer
                                :row-class-name="$_tableRowClassName"
                                @cell-click="cellClickEvent"
                                :print-config="{}"
                                :data="tableDataMain"
                                :columns="tableColumn"
                                :footer-method="footerMethod"
                                :mouse-config="{selected: true}"
                                :edit-config="{showIcon:true,trigger: 'click', mode: 'cell', showStatus: true}"
                                :keyboard-config="{enterToTab:true,isArrow: true, isDel: false, isEnter: true, isTab: true,isEdit:true}">
                            <template #pager>
                                <vxe-pager
                                        :layouts="['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total']"
                                        :page-sizes="[50,500,1000,2500,5000,50000]"
                                        :current-page.sync="tablePage.currentPage"
                                        :page-size.sync="tablePage.pageSize"
                                        :total="tablePage.total"
                                        @page-change="handlePageChange">
                                </vxe-pager>
                            </template>
                        </vxe-grid>
                    </el-col>


                </el-row>

            </el-form>
        </el-main>
    </div>
</template>


<script>
    import {filterRender} from "@/utils/gird-filter";

    export default {

        name: "CashBanks",

        data() {
            var now = new Date();
            var year = now.getFullYear();
            var month = now.getMonth() + 1;
            if (month < 10) {
                month = '0' + month;
           }
            // 本月第一天
            var startDate = new Date(year + '-' + month + '-01 00:00:00');
            return {
                formData:{
                    roleSubject: [],
                    noHappendMoney:false,
                    noMoney:false
               },
                roleSubjectList:[],//授权科目，可以多选
                pickerDate: [startDate,new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')],
                pickerMonthRange: [startDate, new Date()],
                tablePage: {
                    total: 0,
                    currentPage: 1,
                    pageSize: 500,
               },
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '最近一年',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
                            picker.$emit('pick', [start, end]);
                       }
                   }, {
                        text: '本月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth() + 1;
                            if (month < 10) {
                                month = '0' + month;
                           }
                            // 本月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            picker.$emit('pick', [startDate, new Date(year + '-' + month + '-' + new Date(year, month, 0).getDate() + ' 23:59:00')]);
                       }
                   }, {
                        text: '上个月',
                        onClick(picker) {
                            var now = new Date();
                            var year = now.getFullYear();
                            var month = now.getMonth();
                            if (month == 0) {
                                month = 12;
                                year = year - 1;
                           }
                            if (month < 10) {
                                month = '0' + month;
                           }
                            var myDate = new Date(year, month, 0);
                            // 上个月第一天
                            var startDate = new Date(year + '-' + month + '-01 00:00:00');
                            // 上个月最后一天
                            var endDate = new Date(year + '-' + month + '-' + myDate.getDate() + ' 23:59:00');
                            picker.$emit('pick', [startDate, endDate]);
                       }
                   }]
               },
                tableColumn: [
                    {
                        field:'id_subject', width: 100, title: '客户UUID', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/cashBanks/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'subject_name', width: 390, title: '科目名称', slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/cashBanks/searchHeard', this.pickerDate)
                       }
                   }, {
                        field:'money', width: 180, title: '上期数', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/cashBanks/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'money_add_now', width: 180, title: '本期增', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/cashBanks/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'money_sub_now', width: 180, title: '本期减', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/cashBanks/searchHeard', this.pickerDate)
                       }
                   },{
                        field:'end_money', width: 180, title: '本期存', formatter: ({cellValue}) => {
                            return this.$XEUtils.commafy(cellValue ? cellValue : '', {digits: 2})
                       }, slots: {
                            header: (params, h) => filterRender(this, params, h, 'autocomplete', {size: 'mini'}, this.filterData, this.sortData, '/admin/cashBanks/searchHeard', this.pickerDate)
                       }
                   },
                ],
                tableDataMain: [],
                filterData: {},
                sortData: {},
           }
       },
        methods: {

            search() {
                const loading = this.$loading({
                    lock: true,
                    text: '饮茶先啦，数据查询中，请耐心等待!',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
               });
                let searchUrl = '/admin/cashBanks/search'
                this.$axios({
                    method: 'POST',
                    url: searchUrl,
                    data: {
                        currentPage: this.tablePage.currentPage,
                        pageSize: this.tablePage.pageSize,
                        sortData: this.sortData,
                        filterData: this.filterData,
                        pickerDate: this.pickerDate,
                        formData: this.formData,
                   }
               }).then((response) => {         //这里使用了ES6的语法
                    loading.close();
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200) {
                        this.tableDataMain = response.data.list
                        this.tablePage.pageSize = response.data.pageSize
                        this.tablePage.total = response.data.totalRow
                        this.tablePage.currentPage = response.data.pageNumber
                   }
               }).catch((error) => {
                    loading.close();
                    console.log(error)
               });
           },
            handlePageChange({currentPage, pageSize}) {
                this.tablePage.currentPage = currentPage
                this.tablePage.pageSize = pageSize
                this.search();
           },
            sumNum(list, field) {
                let count = Number(0)
                list.forEach(item => {
                    count = this.$XEUtils.add(count, item[field]);
               })
                /*if (field === 'the_theory_of_count'){
                    count = this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
               }else if(field === 'amount_of_product'){
                    count = this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
               }else if(field === 'naked_price'){
                    count = this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
               }*/
                //return this.$XEUtils.commafy(count ? count : '', {digits: 2}) + '';
                return count;
           },
            cellClickEvent({row,rowIndex}){
                if (this.$refs.xGrid.isCheckedByCheckboxRow(row)){
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], false)
                }else{
                    this.$refs.xGrid.setCheckboxRow([this.tableDataMain[rowIndex]], true)
                }
            },
            footerMethod({columns, data}) {
                const sums = []
                columns.forEach((column, columnIndex) => {
                    if (columnIndex === 0) {
                        sums.push('合计')
                   } else {
                        if (
                            column.property === 'before_count' || column.property === 'before_stock_amount' || column.property === 'before_weighing_heavy_total'
                            || column.property === 'now_single_count' || column.property === 'now_naked_price' || column.property === 'now_weighing_heavy'
                            || column.property === 'now_single_count_kd' || column.property === 'now_stock_amount_kd_total' || column.property === 'now_weighing_heavy_kd'
                            || column.property === 'total_count' || column.property === 'total_stock_amount' || column.property === 'total_weighing_heavy_total'
                            || column.property === 'money' || column.property === 'money_add_now' || column.property === 'money_sub_now' || column.property === 'end_money'
                        ) {
                            sums.push(this.sumNum(data, column.property))
                       } else {
                            sums.push('')
                       }
                   }
               })
                //this.autoComputed();
                // 返回一个二维数组的表尾合计
                return [sums]
           },
            //获取不同用户的授权科目权限
            getRoleSubjectByAccountId(){
                this.$axios({
                    method:'post',
                    url:'/admin/roleSubject/getRoleSubjectByAccountId'
               }).then((response) =>{         //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if(response.status === 200){
                        this.roleSubjectList = response.data;
                   }
               }).catch((error) =>{
                    console.log(error)
               });
           }
       },
        created() {
            this.search();
            this.loading = true
            this.getRoleSubjectByAccountId();
            this.$nextTick(() => {
                // 手动将表格和工具栏进行关联
                this.$refs.xGrid.connect(this.$refs.xToolbar2)
           })
       }
   }
</script>

<style scoped>
    /*修改行高*/
    /deep/ .vxe-table--render-default.size--mini .vxe-body--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-footer--column.col--ellipsis, .vxe-table--render-default.size--mini .vxe-header--column.col--ellipsis, .vxe-table--render-default.vxe-editable.size--mini .vxe-body--column{
        height: 24px !important;
   }
    /deep/ .vxe-table--render-default.size--mini .vxe-cell .vxe-default-input, .vxe-table--render-default.size--mini .vxe-cell .vxe-default-select, .vxe-table--render-default.size--mini .vxe-cell .vxe-default-textarea{
        height: 24px !important;
   }
    /deep/ .vxe-input--clear-icon.vxe-icon--close {
        height: 1.2em !important;
   }
    /deep/ .vxe-input.size--mini {
        height: 24px !important;
   }
    /deep/ table .el-input__inner {
        height: 22px !important;
        line-height: 24px !important;
   }
    /deep/ .vxe-table--render-default .vxe-body--column, .vxe-table--render-default .vxe-footer--column, .vxe-table--render-default .vxe-header--column {
        line-height: 20px !important;
   }
    /deep/ .vxe-button.size--mini.type--button.is--circle {
        min-width: 22px !important;
   }
    /deep/ .vxe-button.size--mini.type--button {
        height: 22px !important;
   }
    /deep/ .vxe-button.size--mini{
        font-size: 10px !important;
   }
    /*修改行高*/
</style>
